import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { ImageDataLike } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import DriveScore from '@clearscore/website.drivescore-landing';

import socialMediaImageGb from '../assets/global/drivescore/avatar_drivescore.png';
import Layout from '../components/layout';

const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.getdrivescore';
const APP_STORE_LINK = 'https://apps.apple.com/gb/app/drivescore/id1570100560';
const PRIVACY_COOKIES_URL = 'https://clearscore.com/drive-score-privacy-policy';
const TERMS_URL = 'https://www.clearscore.com/drive-score-terms';

const DriveScoreTemplate = ({ data, location }: PageProps<Queries.DriveScoreTemplateQuery>): React.ReactElement => {
    const images = {
        map: getImage(data.mapImage as ImageDataLike),
        score: getImage(data.scoreImage as ImageDataLike),
        save: getImage(data.saveImage as ImageDataLike),
        appDetailsBetterDriver: getImage(data.appDetailsBetterDriverImage as ImageDataLike),
        appDetailsAnalyse: getImage(data.appDetailsAnalyseImage as ImageDataLike),
        appPreview: getImage(data.appPreviewImage as ImageDataLike),
        appPreviewHero: getImage(data.appPreviewImageHero as ImageDataLike),
        appPreviewHeroLensFlare: getImage(data.appPreviewHeroLensFlare as ImageDataLike),
    };

    return (
        <Layout
            meta={{
                title: 'DriveScore',
                description: 'Sign up to DriveScore for free today',
                image: socialMediaImageGb,
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <DriveScore
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={{
                        ...commonModules.cookie_policy,
                        // Overwritten for this page only
                        moreInfoLinkUrl: PRIVACY_COOKIES_URL,
                    }}
                    images={images}
                    playStoreLink={PLAY_STORE_LINK}
                    appStoreLink={APP_STORE_LINK}
                    privacyUrl={PRIVACY_COOKIES_URL}
                    termsUrl={TERMS_URL}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query DriveScoreTemplate {
        appPreviewImageHero: file(absolutePath: { regex: "/drivescore/appPreviewHero.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
        appPreviewHeroLensFlare: file(absolutePath: { regex: "/drivescore/heroLensFlare.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
        mapImage: file(absolutePath: { regex: "/drivescore/map.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
        scoreImage: file(absolutePath: { regex: "/drivescore/score.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
        saveImage: file(absolutePath: { regex: "/drivescore/save.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
        appDetailsBetterDriverImage: file(absolutePath: { regex: "/drivescore/appDetailsBetterDriver.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    height: 606
                    width: 386
                )
            }
        }
        appDetailsAnalyseImage: file(absolutePath: { regex: "/drivescore/appDetailsAnalyse.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    height: 605
                    width: 461
                )
            }
        }
        appPreviewImage: file(absolutePath: { regex: "/drivescore/appPreview.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
        appPreviewBackgroundImage: file(absolutePath: { regex: "/drivescore/appPreviewBackground.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
    }
`;

export default DriveScoreTemplate;
